
@import "./icons.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

#sidebar-menu {
    ul {
        li {
            a {
                i {
                    min-width: 2rem;
                }
            }
        }
    }
}
.noti-icon .badge {
    left: 23px;
}
.dataTables_filter, .dataTables_paginate {
    float: right;
}
.rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
}
.dz-message {
    text-align: center;
    padding: 30px;
}
// kanban board
.fcYNFc, .sc-AxmLO.gmtmqV {
    background-color: $card-bg!important;
    margin: 0 20px 0 0 !important;
}
.task-box {
    border : 1px solid $border-color;
}
.react-datepicker-wrapper {
    width: 100%!important;
}
.ReactModal__Overlay {
    z-index: 1001!important;
}
.chat-conversation .right .conversation-list {
    margin-right: 15px;
}
.external-event {
    &:hover {
        cursor: pointer;
    }
}
.rating-container {
    background-color: transparent !important;
}
.input-group-append{
    z-index: 0;
}
.input-color{
    color: $input-color !important;
}
.sketch-picker {
    position: absolute;
    z-index: 1;
}
.rangeslider__fill{
    background-color: $primary !important;
}

.pointer {
    cursor: pointer;
}

.underline {
    text-decoration: underline;
}

a.externalLink {
  color: #6060E0;
  text-decoration: underline dotted #9090D0!important;
  font-weight: bold;
}
a:hover {
  cursor: pointer;
  color: blue;
}

.fauxLink {
  cursor: pointer;
}
.fauxLink:hover {
  color: blue;
}
.fauxLinkExternal {
  cursor: pointer;
  color: #6060E0;
  text-decoration: underline dotted #9090D0!important;
  font-weight: bold;
}

.itemDisabled  {
  color: silver;
}

.rmsc {
  --rmsc-main: #3264f5;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #E8E8E8;
  --rmsc-border: #111111;
  --rmsc-gray: #111111;
  --rmsc-bg: #F8F8F8;
  --rmsc-p: 7px; /* Spacing */
  --rmsc-radius: 15px; /* Radius */
  --rmsc-h: 30px; /* Height */
}

.noBorder {
  border: none;
  outline: none;
}

.loadingOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51,51,51,0.3);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
